<template>
  <!-- v-on:mousemove="updateCoordinates" -->
  <div id="showcase-main">
    <div id="yt-video-wrapper">
      <div id="play-btn" v-if="isInInitState">
        <a href="#">&#x25ba;</a>
      </div>

      <YouTube
        @ready="onReady"
        @stateChange="onStateChange"
        ref="youtube"
        id="yt-video"
        :class="{ makeBlur: isInInitState }"
        :vars="params"
      />
    </div>

    <TheControlBar />
  </div>
</template>

<script>
/* eslint-disable */
    import { ref, computed, watch, inject } from 'vue';
    import { useStore } from "vuex";
    import YouTube from 'vue3-youtube';
    
    import TheControlBar from './TheControlBar';
    import videoLogic from "../utils/video-utils";

    export default {
        components: { 
            YouTube,
            TheControlBar,
        },

        setup() {
            const store = useStore();
            let socket = inject('socket');

            //cc_load_policy
            const videoUrl = ref("");
            const youtube = ref(null);
            const params = ref({
                rel: 0,
                start: 0,
                fs: 0,
                disablekb: 1,
            });
            const isReady = ref(false);
            const currentTime = ref(0);
            const isInFullscreen = ref(false);

            const isRunning = ref(false);

            const isInInitState = ref(true);
            let isVideoLoaded = false;

            let currentTimeListener = null;

            const onReady = () => {
                isReady.value = true;
                console.log(store.state.clientId);
            };

            const onStateChange = (event) => {
                
                /*
                    -1 (nicht gestartet)
                    0 (beendet)
                    1 (wird wiedergegeben)
                    2 (pausiert)npm ru
                    3 (wird gepuffert)
                    5 (Video positioniert)
                */

                if(event.data === 1) {
                    isRunning.value = true;
                }
                else if(event.data === 2) {
                    isRunning.value = false;
                } else if(event.data === 5) {
                    isVideoLoaded = true;
                    //the video is already placed
                } 

            };

            const onStart = () => {
                console.log('on start');
                currentTime.value = Math.floor(youtube.value.getCurrentTime());

                if(isVideoLoaded && isInInitState.value) {
                    //first start after video loaded
                    youtube.value.pauseVideo();
                    return;
                }

                socket.emit('video-data-update', JSON.stringify(getVideoStateToEmit(1)));

                currentTimeListener = setInterval(() => {
                        if(isVideoLoaded)
                            currentTime.value = Math.floor(youtube.value.getCurrentTime());
                    }, 1000);

            };

            const onPause = () => {
                console.log('on pause');
                
                if(isVideoLoaded && isInInitState.value) {
                    //automatic stop after video loaded and played
                    socket.emit('get-video-state');
                    isInInitState.value = false;
                    return;
                }

                clearInterval(currentTimeListener);
                socket.emit('video-data-update', JSON.stringify(getVideoStateToEmit(2)));
            };

            const getTimeFormatted = (sec) => {
                return Math.floor(sec/60) + ":" + ('0' + (sec%60)).slice(-2);
            }

            const currentTimeLabel = computed(() => {
                console.log(Math.floor(currentTime.value) + " / " + Math.floor(youtube.value.getCurrentTime()));
                return getTimeFormatted(currentTime.value);
            });

            const durationTimeLabel = computed(() => {
                return getTimeFormatted(youtube.value.getDuration());
            });

            const onSliderInput = () => {
                youtube.value.seekTo(currentTime.value, true);
            };

            socket.on('share-video-with', toClientId => {
                console.log('send video with video id: ' + youtube.value.getVideoUrl());
                socket.emit('video-data-update-to', JSON.stringify({
                    type: "video",
                    videoId: videoLogic.filterVideoId(youtube.value.getVideoUrl()),
                    clientId: store.state.clientId,
                    toClientId: toClientId,
                }));
            });

            socket.on('share-video-state-with', toClientId => {
                let data = getVideoStateToEmit();
                data.toClientId = toClientId
                socket.emit('video-data-update-to', JSON.stringify(data));
            });

            socket.on('video-data-update', data => {
                const msg = JSON.parse(data);

               if(isInInitState.value && msg.type !== "video") return;

                console.log('received video data');
                if(msg.type === "video-state") 
                    socketVideoExecutionHandler(msg);
                else if(msg.type === "video") 
                    socketVideoUpdateHandler(msg);

            });

            const socketVideoExecutionHandler = (data) => {
                /*
                    type: "video-execution",
                    state: int,
                    currenTime: int in sec
                */
                youtube.value.seekTo(data.currentTime, true);

                switch(data.state) {
                    case 1:
                        //play
                        console.log('play video by socket');
                        youtube.value.playVideo();
                        break;
                    case 2:
                        //pause
                        console.log('pause video by socket');
                        youtube.value.pauseVideo();
                        break;
                }
            }

            const socketVideoUpdateHandler = (data) => {
                /*
                    type: "video-update",
                    videoId: int,
                */
               console.log('set video');
               isVideoLoaded = false;
               currentTime.value = 0;
               isRunning.value = false;
               if(data.videoId === false) alert("Failed getting video");
               console.log(data.videoId);
               if(youtube.value == null) {
                alert("Something went wrong :(");
               }
               youtube.value.cueVideoById(data.videoId);
            }

            const getVideoStateToEmit = (stateId = isRunning.value ? 1 : 2) => {
                return {
                    type: "video-state",
                    state: stateId,
                    currentTime: Math.floor(youtube.value.getCurrentTime()),
                    clientId: store.state.clientId
                };
            }

            watch(() => isRunning.value, () => {
                if(isRunning.value) onStart();
                else onPause();
            });

            return {
                videoUrl,
                onReady,
                onStateChange,
                params,
                youtube,
                isReady,
                isInInitState,
                currentTime,
                currentTimeLabel,
                durationTimeLabel,
                isInFullscreen,
                isRunning
            }
        }
    }
</script>

<style lang="scss">
    @import '../utils/css/play-button.scss';

    #showcase-main {
        position: relative;
        flex: 1;
        color: white;
        display: flex;
        flex-direction: column;

        #yt-video-wrapper{
            position: relative;
            flex: 1;
            #yt-video {
                width: 100% !important;
                height: 100% !important;
            }

            .makeBlur {
                filter: blur(30px);
                -webkit-filter: blur(30px);
            }

            iframe {
                width: 100% !important;
                height: 100% !important;
                display: block;
                margin: 0 auto;
                //pointer-events: none;
            }
        }

        #connected-info {
            span {
                width: 100px;
                margin: 0 5px;
                opacity: 0.2;
            }
        }

        #controls-bar {
            display: flex;
            width: 100%;
            padding: 10px 20px 30px 20px;
            button { 
                margin-right: 20px;
                outline: none;
                border: none;
                background: none;
                color: #fff;
                font-size: 30px;
                margin-bottom: 5px;
                width: 50px;
                height: 50px;
            }
            .slidecontainer {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;

                .slider {
                    -webkit-appearance: none;
                    width: 100%;
                    height: 10px;
                    background: #fff;
                    outline: none;
                    opacity: 0.7;
                    -webkit-transition: .2s;
                    transition: opacity .2s;
                    border-radius: 5px;
                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        appearance: none;
                        width: 25px;
                        height: 25px;
                        background: #1504aa;
                        cursor: pointer;
                    }
                    &::-moz-range-thumb {
                        width: 25px;
                        height: 25px;
                        background: #1504aa;
                        cursor: pointer;
                    }
                    &:hover {
                        opacity: 1;
                    }
                }
            }

            #time-state {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 10px;
            }
        }
    }
</style>