<template>
    <div>
        <div v-if="!props.hostMode">
            <span class="whiteLabel">Join existing room:</span>
            <div id="main-submit">
            <input type="text" class="main-input" v-model="roomName" placeholder="Room-Name" />
            <button class="main-button" @click="emit('onRoomNameSubmit', roomName)">
                &#x279C;
            </button>
            </div>
            <p class="error-msg">{{ props.errMsg }}</p>
            <div id="switch-submit">
            <div id="switch-icon">
                <a @click="emit('onChangeUserMode', true)" class="clickable">&#xFF0B;</a>
            </div>
            <span @click="emit('onChangeUserMode', true)" class="clickable"
                >Create New Room</span
            >
            </div>
        </div>

        <div v-else>
            <span class="whiteLabel">Join existing room:</span>
            <div id="main-submit">
            <input
                type="text"
                v-model="roomName"
                class="main-input"
                placeholder="Create Room-Name"
            />
            <button class="main-button" @click="emit('onRoomNameSubmit', roomName)">
                &#xFF0B;
            </button>
            </div>
            <p class="error-msg">{{ props.errMsg }}</p>
            <div id="switch-submit">
            <div id="switch-icon">
                <a @click="emit('onChangeUserMode', false)" class="clickable">&#x279C;</a>
            </div>
            <span
                @click="emit('onChangeUserMode', false)"
                class="clickable"
                :class="{ whiteLabel: props.hostMode }"
                >Join Existing Room</span
            >
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
  import { ref, inject, watch } from 'vue';
  import { useStore } from "vuex";


  export default {
      components: {
      },

      props: [
          'hostMode',
          'errMsg'
      ],

      setup(props, { emit }) {
        const roomName = ref("");

        return {
            props,
            emit,
            roomName
        }
      }

  }
</script>

<style lang="scss" scoped>

    input {
        max-width: calc(95% - 60px);
    }

</style>