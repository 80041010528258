<template>
  <div id="chat-list">
    <div id = "scrollableChat">
      <ChatListItem
        v-for="item in $store.state.messages"
        :key="item.id"
        :item="item"
        :myClientId="$store.state.clientId"
        id="chat-list-item"
      />
    </div>
  </div>
</template>

<script>
/** eslint-disable **/
  import { watch } from 'vue';
import { useStore } from "vuex";
import ChatListItem from "./ChatListItem";

export default {
  components: {
    ChatListItem,
  },
  setup() {
    const store = useStore();

    watch(
      () => store.state.messages, () => {
        const elem = document.getElementById("scrollableChat");
        elem.scrollTo(0, elem.scrollHeight);
    }, { deep: true });

    return {
      store,
    };
  },
};
</script>

<style lang="scss" scoped>
#chat-list {
  position: relative;
  flex: 1;
  overflow: hidden;
  padding: 0 0 50px 0;

  #chat-list-item {
    z-index: 1;
  }

  #scrollableChat {
    max-height: 100%;
    overflow: auto;
    padding-bottom: 50px;
  }

  #background-img {
    width: 100px;
    height: fit-content;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: white;
    font-size: 70px;
    opacity: 0.1;
    z-index: 0;

    p:last-child {
      text-align: right;
      margin-top: -50px;
    }
  }
}
</style>
