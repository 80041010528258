<template>
  <div id="control-bar" :class="{ hiddenBar: $store.state.isInFullscreen }">
    <div id="search-video">
      <button v-if="!isInSearchMode" class="bar-icon" @click="onSearchOpen">
        🔎︎
      </button>
      <input
        v-if="isInSearchMode"
        type="text"
        placeholder="Youtube link"
        id="yt-url-input"
        ref="inputElement"
      />
      <button
        v-if="isInSearchMode"
        class="bar-icon submit-icon"
        @click="onSearch"
      >
        🔎︎
      </button>
      <button
        v-if="isInSearchMode"
        class="bar-icon cancel-icon"
        @click="isInSearchMode = false"
      >
        &#10006;
      </button>
    </div>

    <div id="connected-info">
      <span v-if="$socket.disconnected">Disconnected</span>
      <span v-if="$socket.connected">Connected</span>
    </div>

    <div id="chat">
      <button class="bar-icon" 
        :class="{ blueOverlay: $store.state.unreadMsgsExists }"
        @click="emitter.emit('toggleChat')">
        &#x1F4AC;
      </button>
    </div>

    <div id="full-screen">
      <button class="bar-icon" @click="emitter.emit('toggleFullscreen')">
        &#x26F6;
      </button>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
  import { ref, inject, watch } from 'vue';
  import { useStore } from "vuex";
  import videoLogic from "../utils/video-utils";

  export default {
      components: {
      },

      setup(_, context) {
        const emitter = inject("emitter");
        const store = useStore();
        let socket = inject('socket');
        const inputElement = ref(null);
        const isInSearchMode = ref(false);

        const onSearchOpen = () => {
          isInSearchMode.value = true;
        }

        watch(() => isInSearchMode.value, () => {
          if(!isInSearchMode.value)
            inputElement.value.removeEventListener('blur', closeSearch, true);

        });

        const closeSearch = () => {
          isInSearchMode.value = false;
        }

        const onSearch = () => {
          console.log('on search:')
            const urlInput = inputElement.value.value;
            const videoId = videoLogic.filterVideoId(urlInput);
            console.log('video id: ' + videoId)
            if(videoId === false) {
                return;
            }

            isInSearchMode.value = false;

            socket.emit('video-data-update', JSON.stringify({
                type: "video",
                videoId: videoId,
                clientId: store.state.clientId,
            }));

        }

        return {
          emitter,
          inputElement,
          isInSearchMode,
          onSearchOpen,
          onSearch,
        }
      }

  }
</script>

<style lang="scss" scoped>
    #control-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        
        button {
            background: none;
            border: none;
            outline: none;
            font-size: 30px;
            height: 100%;
            overflow: hidden;
        }

        .bar-icon {
            position: relative;
            color: #fff;
            cursor: pointer;
            
            padding: 4px;
        }

        .redOverlay::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(129, 20, 20, 0.4);
        }

        .blueOverlay::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(20, 45, 129, 0.4);
        }

        .submit-icon {
            color: rgb(156, 192, 156);
        }

        .cancel-icon {
            color: rgb(194, 179, 179);
        }

        #search-video {
            display: flex;

            .bar-icon {
              padding-left: 15px;
            }

            input {
                padding: 0 5px;
                border-radius: 10px;
                border: none;
                margin: 5px 5px;

                &::placeholder {
                    color: #1504aa;
                    opacity: 0.7;
                }
            }
        }

        #camera-settings {
            & > div {
                display: inline;
            }

            .speaker {
                margin-right: 10px;
            }

            .camera {
            }
        }

        #full-screen {
          .bar-icon {
              padding-right: 15px;
          }
        }
    }

    .hiddenBar {
        height: 1px;
        opacity: 0;
        transition-property: height, opacity;
        transition: all 0.1;

        &:hover {
            height: auto;
            opacity: 1;
        }
    }
</style>