<template>
  <div id="notification">
      <p v-for="user in users" :key="user.id"
        class="item" :class="{disconnected: user.type === 'disconnected'}">
        {{ user.name + " " + user.type}}</p>
  </div>
</template>

<script>
/* eslint-disable */
  import { ref, inject, watch } from 'vue';
  import { useStore } from "vuex";

  export default {
    name: 'UserJoinedNotificiation',
    components: {
    },

    setup(_, context) {
      const store = useStore();
      const emitter = inject("emitter");

      const users = ref([]);

      emitter.on('connection-notification', (data) => {
        let tmp = data.user;
        if(data.type === "connected") {
          tmp.type = "connected";
          users.value.push(tmp);
        } else {
          tmp.type = "disconnected";
          users.value.push(tmp);
        }

        setTimeout(() => {
          //remove it again
          users.value = users.value.filter(user => user.id != tmp.id);
        }, 5000)
      })

      return {
        users,       
      }
    }

  }
</script>

<style lang="scss" scoped>
  #notification {
    position: absolute;
    z-index: 9999;
    bottom: 0;
    right: 10px;
    width: 150px;
    height: auto;
    pointer-events: none;

    .item {
        width: 100%;
        background-color: rgba(26, 27, 29, 0.98);
        color: #209033;
        padding: 5px 10px 5px 10px;
        margin: 5px;
        border-top-left-radius: 10px;
    }

    .disconnected {
        color: #b46d6d;
    }
  }
</style>