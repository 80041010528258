<template>
  <div id="login">
    <div id="sub-container" :class="{ rotateBackground: hostMode }">
      <TheRoomName v-if="roomNameInputMode"
        :hostMode="hostMode"
        :errMsg="errMsg"
        @onRoomNameSubmit="evaluateRoomName"
        @onChangeUserMode="onChangeUserMode" />
      <TheRoomPassword v-else
        :hostMode="hostMode"
        :errMsg="errMsg"
        @onPasswordSubmit="evaluatePassword"
        @onChangeRoomMode="roomNameInputMode=true" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
    import { ref, watch, inject, onBeforeMount } from 'vue';
    import { useRouter, useRoute } from 'vue-router'
    import TheRoomName from "../components/login/TheRoomName.vue";
    import TheRoomPassword from "../components/login/TheRoomPassword.vue";

    export default {
        name: "Login",

        components: {
          TheRoomName,
          TheRoomPassword
        },

        setup(props) {
            let socket = inject('socket');
            const router = useRouter()
            const route = useRoute()

            const roomNameInputMode = ref(true);
            const hostMode = ref(false);

            const errMsg = ref("");
            let roomName = null;

            onBeforeMount(() => {
              if(route.params.roomName !== undefined) {
                roomName = route.params.roomName;
                roomNameInputMode.value = false;
                hostMode.value = false;
              }

              if(route.params.errMsg !== undefined) {
                errMsg.value = route.params.errMsg;
              }

            })
            const isValidRoomNameInput = () => {

              if(roomName !== null 
                && roomName.length >= 4 && roomName.length <= 10
                && !(/\s/.test(roomName))) {
                return {
                  err: false,
                }
              }
              return {
                      err: true,
                      errMsg: 'Invalid room name (min 4-10 characters, no white-spaces)'
                  }
            }

            const evaluateRoomName = (roomNameP) => {

                roomName = roomNameP.trim();
                roomName = roomName.toLowerCase();
                
                const state = isValidRoomNameInput();

                if(state.err) {
                    errMsg.value = state.errMsg;
                    return;
                }

                socket.emit('check-room-valid', roomName, (valid, exists, error, pswRequired) => {
                    
                    if(!valid) {
                        errMsg.value = error;
                        return;
                    }

                    //host
                    if(hostMode.value) {
                        if(exists) {
                            errMsg.value = 'Room already exists';
                            return;
                        }
                        //send user to password form
                        roomNameInputMode.value = false;
                        return;
                    }

                    //not host
                    if(!exists) {
                        errMsg.value = 'Room does not exists';
                        return;
                    }

                    if(pswRequired) {
                        //send user to password form
                        roomNameInputMode.value = false;
                    } else {
                        //no psw requiered, send user to new page
                        router.push({
                            name: 'Main',
                            params: { roomName: roomName } 
                        });
                    }

                });
            }

            const evaluatePassword = (psw) => {
              psw = psw.trim();

              //not host
              if(!hostMode.value) {
                if(psw.length === "") {
                  errMsg.value = 'Password is required.';
                  return;
                }

                if(roomName === null) {
                  errMsg.value = 'Room name is requierd.';
                  return;
                }
                //send him to main page
                router.push({
                  name: 'Main',
                  params: { roomName: roomName, psw: psw } 
                });

                return;

              }

              //host

              //save roomName with password
              socket.emit('create-room', roomName, psw, (err, errMsg) => {
                if(err) {
                  errMsg.value = errMsg;
                  return;
                }

                router.push({
                  name: 'Main',
                  params: { roomName: roomName, psw: psw } 
                });
              });

            }

            const onChangeUserMode = (hostModeP) => {
              hostMode.value = hostModeP;
            }

            watch(() => hostMode.value, () => {
                errMsg.value = "";
            });

            watch(() => roomNameInputMode.value, () => {
                errMsg.value = "";
            });

            return {
                hostMode,
                errMsg,
                roomNameInputMode,
                onChangeUserMode,
                evaluateRoomName,
                evaluatePassword,
            }
        }
    }

</script>

<style lang="scss">

    #login {
        width: 100%;
        height: 100vh;
        background-color: #202124;
        background-image: -webkit-linear-gradient(30deg, #202124 50%, #a56060 50%);
    
        #sub-container {
          position: absolute;
          width: 700px;
          max-width: 95%;
          height: auto;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgb(252, 251, 255);
          border-radius: 10px;
          box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
            rgba(0, 0, 0, 0.22) 0px 15px 12px;
          padding: 20px 10px;
          background-image: -webkit-linear-gradient(-30deg, #202124 50%, #fff 50%);
        
          &.rotateBackground {
            background-image: -webkit-linear-gradient(
              30deg,
              #202124 50%,
              #fff 50%
            ) !important;
          }

          span {
            font-size: 14px;
          }

          .whiteLabel {
            color: rgb(219, 220, 226);
          }

          .clickable {
            cursor: pointer;
            -webkit-user-select: none; /* Safari */
            -moz-user-select: none; /* Firefox */
            -ms-user-select: none; /* IE10+/Edge */
            user-select: none; /* Standard */
          }

          .error-msg {
            color: red;
            margin-top: 10px;
            width: 40%;
          }

          input {
            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: #902020;
              opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: #902020;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: #902020;
            }
          }

          #main-submit {
            display: flex;
            width: 100%;
            margin-top: 2px;

            .main-input {
              flex: 1;
              outline: none;
              border: 1px solid #c8b9b9;
              font-size: 25px;
              padding: 10px;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
              background-color: #fff;
            }

            .main-button {
              outline: none;
              border: none;
              background-color: #902020;
              color: white;
              font-size: 35px;
              padding: 0 20px;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 10px;
              font-weight: bold;
            }
          }

          #switch-submit {
            text-align: center;
            margin-top: 70px;

            &::before {
              content: "";
              border-bottom: 1px solid rgb(227, 227, 231);
              width: 50%;
              display: block;
              margin: 0 auto;
              margin-bottom: 20px;
            }

            #switch-icon {
              padding-bottom: 20px;
              a {
                text-decoration: none;
                background-color: #902020;
                border: 2px solid #fff;
                font-size: 30px;
                color: #fff;
                padding: 5px;
                border-radius: 50%;
              }
            }
          }

          #back-icon {
            padding: 20px 0 10px 0;
            a {
              text-decoration: none;
              background-color: #902020;
              border: 2px solid #fff;
              font-size: 30px;
              color: #fff;
              padding: 5px;
              border-radius: 50%;
            }
          }


        }
    }

</style>