import { createApp } from "vue";
import App from "./App.vue";
import VueSocketIOExt from "vue-socket.io-extended";
import store from "./store/store";

import { io } from "socket.io-client";

import mitt from "mitt"; // Import mitt
import router from "./router";
const emitter = mitt(); // Initialize mitt
//console.log(location.origin);
const socket = io("https://royal-square-list.glitch.me"); //"http://localhost:3000");

console.log("---------");

const app = createApp(App).use(router);
app.provide("emitter", emitter);
app.use(store);
app.use(VueSocketIOExt, socket, { store });
app.provide("socket", socket);
app.mount("#app");
