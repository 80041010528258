<template>
    <div>
        <div v-if="!props.hostMode">
          <span class="whiteLabel">Enter password:</span>
          <div id="main-submit">
            <input
              type="text" 
              v-model="psw" 
              class="main-input" 
              placeholder="Room-Password" />
            <button class="main-button" @click="emit('onPasswordSubmit', psw)">&#x279C;</button>
          </div>
          <p class="error-msg">{{ props.errMsg }}</p>
          <div id="back-icon">
            <a @click="emit('onChangeRoomMode')" class="clickable">&#x2BAA;</a>
          </div>
        </div>

        <div v-else>
          <span class="whiteLabel">Create password: <b>(optional)</b></span>
          <div id="main-submit">
            <input
              type="text"
              v-model="psw"
              class="main-input"
              placeholder="Create Room-Password"
            />
            <button class="main-button" @click="emit('onPasswordSubmit', psw)">&#xFF0B;</button>
          </div>
          <p class="error-msg">{{ props.errMsg }}</p>
          <div id="back-icon">
            <a @click="emit('onChangeRoomMode')" class="clickable">&#x2BAA;</a>
          </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
  import { ref, inject, watch } from 'vue';
  import { useStore } from "vuex";


  export default {
    components: {
    },
    props: [
        'hostMode',
        'errMsg'
    ],

      setup(props, { emit }) {
        const psw = ref("");


        return {
            props,
            emit,
            psw
        }
      }

  }
</script>

<style lang="scss" scoped>

    input {
        max-width: calc(95% - 60px);
    }


</style>