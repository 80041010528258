<template>
  <div id="main">
    <div id="showcase" v-if="readyToLoad">
      <TheShowcaseMain />
    </div>
    <TheChat/>
      <!-- absolute positioned element -->
    <TheUsernamePicker v-if="readyToLoad && inUsernamePickerMode"
      :isInvalidUsername="isInvalidUsername"
      @onUsernameSubmit="onUsernameSubmit" />

    <UserJoinedNotificiation />
  </div>
</template>

<script>
/* eslint-disable */
import { ref, watch, onBeforeMount, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router'
import { useStore } from "vuex";
import TheShowcaseMain from "../components/TheShowcaseMain";
import TheChat from "../components/TheChat";
import TheUsernamePicker from "../components/TheUsernamePicker";
import UserJoinedNotificiation from "../components/UserJoinedNotificiation";

export default {
    components: {
        TheShowcaseMain,
        TheChat,
        TheUsernamePicker,
        UserJoinedNotificiation,
    },

    setup() {
        const router = useRouter();
        const route = useRoute();
        let socket = inject('socket');
        const store = useStore();
        const emitter = inject("emitter");

        const readyToLoad = ref(false);
        const inUsernamePickerMode = ref(true);
        const isInvalidUsername = ref(false);

        

        onBeforeMount(() => {
            console.log(route.params)

            socket.emit('has-room-permission', 
                        route.params.roomName, route.params.psw, 
                        (hasPermission, errMsg) => {
                console.log('perm: ' + hasPermission + " " + errMsg)
                if(!hasPermission) {
                  //send him back to login page
                  router.push({
                      name: 'Login',
                      params: {
                        roomName: route.params.roomName,
                        errMsg: errMsg 
                      } 
                  });
                  return;
                }

                readyToLoad.value = true;

            });
        });

        const onUsernameSubmit = (userName) => {
            userName = userName.trim();

            socket.emit('join-room', 
                        userName, route.params.roomName, route.params.psw, 
                        (hasPermission, isValidUsername, errMsg, users) => {
                
                if(!isValidUsername) {
                  console.log('invl username')
                  isInvalidUsername.value = true;
                  return;
                }

                if(!hasPermission) {
                  //send him back to login page
                  router.push({
                      name: 'Login',
                      params: {
                        roomName: route.params.roomName,
                        errMsg: errMsg 
                      } 
                  });
                  return;
                }

                store.commit('set_userName', userName);
                store.commit('set_users', users);
                inUsernamePickerMode.value = false;

                socket.emit('get-video');

            });
        }

        socket.on('user-joined', user => {
          store.commit('add_user', JSON.parse(user));
          //show popup
          emitter.emit('connection-notification', {
            type: 'connected',
            user: JSON.parse(user)
          });
        })

        socket.on('user-disconnected', user => {
          store.commit('remove_user', user.id);
          //show popup
          emitter.emit('connection-notification', {
            type: 'disconnected',
            user: JSON.parse(user)
          });
        })

        emitter.on('toggleFullscreen', () => {
          console.log('toggle screen')
          let elem = document.getElementById("main"); 
          if(!store.state.isInFullscreen) {
            //request full screen
            if (elem.requestFullscreen) {
              elem.requestFullscreen();
            } else if (elem.webkitRequestFullscreen) { /* Safari */
              elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE11 */
              elem.msRequestFullscreen();
            }
            return;
          }

          console.log('exit full screen')

          //exit full fullscreen
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.webkitExitFullscreen) { /* Safari */
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { /* IE11 */
            document.msExitFullscreen();
          }


        });

        document.addEventListener("fullscreenchange", function() {
          handleFullScreenEvent();
        });
        document.addEventListener("mozfullscreenchange", function() {
          handleFullScreenEvent();
        });
        document.addEventListener("webkitfullscreenchange", function() {
          handleFullScreenEvent();
        });
        document.addEventListener("msfullscreenchange", function() {
          handleFullScreenEvent();
        });

        const handleFullScreenEvent = () => {
          if (document.fullscreenElement) {
            //entered full screen
            store.commit('set_fullscreen', true);
          } else {
            //leaving full screen mode
            store.commit('set_fullscreen', false);
          }
        };

        return {
          readyToLoad,
          inUsernamePickerMode,
          onUsernameSubmit,
          isInvalidUsername,
        }
  },
};
</script>

<style lang="scss">
    #main {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100vh;

        #showcase {
            flex: 1;
            display: flex;
            flex-direction: column;
            background-color: #202124;
            overflow: hidden;
        }
    }
</style>
