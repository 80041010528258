<template>
  <div id="chat" v-if="isOpen">
      <div id = "close-area" v-on:click="isOpen = false">✖</div>
      <TheChatList />
      <TheChatForm @submitMessage="submitMessage" />
</div>
</template>

<script>
/* eslint-disable */
  import { ref, inject, watch } from 'vue';
  import { useStore } from "vuex";

  import TheChatList from './TheChatList';
  import TheChatForm from './TheChatForm';

  export default {
      components: {
        TheChatList,
        TheChatForm
      },

      setup(_, context) {
        const emitter = inject("emitter");
        const store = useStore();
        let socket = inject('socket')

        const isOpen = ref(false);

        const submitMessage = (msg) => {
          socket.emit('message', msg);
        }

        emitter.on('toggleChat', () => {
          isOpen.value = !isOpen.value;
          if(isOpen) {
            store.commit('set_unreadMessagesExists', false);
          }
        });

        watch(() => store.state.messages, () => {

          if(!isOpen.value) {
            //new unread messages
            store.commit('set_unreadMessagesExists', true);
          }
        }, { deep: true });

        watch(() => store.state.isInFullscreen, (newValue, oldValue) => {
          if(newValue)
            isOpen.value = false;

        });

        window.addEventListener('click', function(e){
          if(isOpen.value)
            if (document.getElementById('chat').contains(e.target)){
              //isOpen.value = false;
            }
        })

        return {
          submitMessage,
          isOpen,
        }
      }

  }
</script>

<style lang="scss" scoped>
  #chat {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 300px;
    max-width: 100%;
    overflow: hidden;
    pointer-events: auto;

    #close-area {
      width: 100%;
      display: flex;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      color: rgb(153, 50, 50);
    }
  }
</style>