/* eslint-disable */

import { createStore } from "vuex"

const store = createStore({
    state: { 
      clientId: null,
      userName: "unkown",
      messages: [],
      isInFullscreen: false,
      isMuted: false,
      isCameraOn: true,
      users: [],
      unreadMsgsExists: false,
     },
    mutations: {
      CONNECTED(state, data) {
        let msg = JSON.parse(data);

        console.log('socket connected');
        state.clientId = msg.clientId;
        state.clientName = "test" + Math.floor(Math.random()*100);
      },
      ADD_MESSAGE(state, data) {
        let msg = JSON.parse(data);
        state.messages.push(msg);
      },
      set_fullscreen(state, fullscreen) {
        state.isInFullscreen = fullscreen;
      },
      set_userName(state, userName) {
        state.userName = userName;
      },
      set_users(state, users) {
        state.users = users;
      },
      add_user(state, user) {
        state.users.push(user);
      },      
      remove_user(state, userId) {
        state.user = state.users.filter(user => user.id != userId);
      },
      set_unreadMessagesExists(state, unreadMsgsExists) {
        state.unreadMsgsExists = unreadMsgsExists;
      }
    },
    actions: {
      //triggered when `user_message`
      socket_userMessage ({ commit }, data) {
        commit('ADD_MESSAGE', data);
      },
      socket_connected ({ commit }, data) {
        commit('CONNECTED', data);
      },
    },
    getters: {
      get_userById: (state) => (userId) => {
        for(const user of state.users) {
          if(user.id === userId) return user;
        }
        return null;
      }
    }
})

export default store
