<template>
    <div id="nickname-picker">
        <div id="picker">
            <div class="form__group">
                <input type="input" v-model="userName" class="form__field" placeholder="Name" name="name" id='name' required />
                <label for="name" class="form__label">Name</label>
            </div>
            <div v-if="props.isInvalidUsername" class="err-msg">
                Choose username:
                <ul>
                    <li>4-10 characters</li>
                    <li>no white-spaces</li>
                </ul>
            </div>
            <button @click="emit('onUsernameSubmit', userName)">&#x279C;</button>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
  import { ref, inject, watch } from 'vue';
  import { useStore } from "vuex";

  export default {
      components: {
      },
      props: [
          'isInvalidUsername'
      ],

      setup(props, { emit }) {
        const userName = ref(null);


        return {
            props,
            emit,
            userName,
        }
      }

  }
</script>

<style lang="scss" scoped>
  #nickname-picker {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(27, 28, 36, 0.98);
        z-index: 9999;

        #picker {
            position: absolute;
            width: 400px;
            max-width: 95%;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999;
            color: rgb(252, 251, 255);

            $primary: rgb(95, 108, 121);
            $secondary: #253488;
            $white: #fff;
            $gray: rgb(252, 251, 255);

            .err-msg {
                color: rgb(158, 35, 35);
                margin-top: 15px;

                ul {
                    margin-left: 20px;
                }
            }

            button {
                width: 100%;
                height: 50px;
                margin-top: 20px;
                font-size: 30px;
                color: rgb(252, 251, 255);
                background-color: #253488;
                outline: none;
                border: none;
                cursor: pointer;
            }

            .form__group {
                position: relative;
                padding: 15px 0 0;
                margin-top: 10px;
            }

            .form__field {
                width: 100%;
                font-family: inherit;
                border: 0;
                border-bottom: 2px solid $gray;
                outline: 0;
                font-size: 1.3rem;
                color: $white;
                padding: 7px 0;
                background: transparent;
                transition: border-color 0.2s;

                &::placeholder {
                    color: transparent;
                }

                &:placeholder-shown ~ .form__label {
                    font-size: 1.3rem;
                    cursor: text;
                    top: 20px;
                }
            }

            .form__label {
                position: absolute;
                top: 0;
                display: block;
                transition: 0.2s;
                font-size: 1rem;
                color: $gray;
            }

            .form__field:focus {
            ~ .form__label {
                position: absolute;
                top: 0;
                display: block;
                transition: 0.2s;
                font-size: 1rem;
                color: $primary;
                font-weight:700;    
            }
            padding-bottom: 6px;  
            font-weight: 700;
            border-width: 3px;
            border-image: linear-gradient(to right, $primary,$secondary);
            border-image-slice: 1;
            }
            /* reset input */
            .form__field{
            &:required,&:invalid { box-shadow:none; }
            }
        }
  }
</style>