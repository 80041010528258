<template>
  <div class="message" :class="{ personal: itsMe }">
    <span v-if="!itsMe" class="user-name">{{ props.item.clientName }}</span>
    <span class="user-msg">{{ props.item.msg }}</span>
  </div>
</template>

<script setup>
/* eslint-disable */

    const props = defineProps({ 
        item: Object, 
        myClientId: String 
    })

    console.log(props.item.clientId + " / " + props.myClientId)
    let itsMe = props.item.clientId === props.myClientId;

</script>

<style lang="scss">
    .message {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        padding: 6px;
        margin-top: 10px;
        margin-left: 10px;
        width: fit-content;
        max-width: 90%;
        background-color: #fff;

        .user-name {
            color: #1c56ba;
            padding-bottom: 5;
        }

        .user-msg {
            font-size: 10px;
        }
    }

    .personal {
        margin-left: auto;
        margin-right: 10px;
        background-color: #1c56ba;
        color: white !important;
    }
</style>