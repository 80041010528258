<template>
  <div id="chat-form">
    <div id="form-wrapper">
      <!-- https://codesandbox.io/s/vue-template-forked-578we?file=/src/ResizableTextarea.vue:73-87 -->
      <div id="textInputWrapper">
        <textarea
          maxlength="150"
          v-model="message"
          ref="textInput"
          placeholder="Schreib hier ..."
        ></textarea>
      </div>
      <button @click="submitMessage">Send</button>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export default {
  components: {},

  setup(_, { emit }) {
    const textInput = ref(null);
    const message = ref("");

    watch(
      () => message.value,
      () => {
        //resize text input
        textInput.value.setAttribute("style", "height: 25px;");
        textInput.value.setAttribute(
          "style",
          "height:" + textInput.value.scrollHeight + "px;"
        );
      }
    );

    const submitMessage = () => {
      let tmp = message.value.trim();
      if (tmp.length == "") {
        message.value = "";
        return;
      }

      message.value = "";

      emit("submitMessage", tmp);
    };

    return {
      message,
      textInput,
      submitMessage,
    };
  },
};
</script>

<style lang="scss">
#chat-form {
  display: flex;
  flex-direction: row;

  #form-wrapper {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 10px 10px 20px 10px;
    overflow: hidden;

    #textInputWrapper {
      flex: 1;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      margin-right: 10px;
      background-color: white;
      overflow: hidden;

      textarea {
        font-size: 18px;
        resize: none;
        outline: none;
        overflow: hidden;
        min-height: 25px;
        height: 25px;
        max-height: 150px;
        border: none;
        margin: 5px 35px;
        width: 100%;
      }
    }

    button {
      padding: 10px;
      color: white;
      background-color: #1c56ba;
      width: 50px;
      height: 50px;
      border-radius: 15px;
      border: none;
    }
  }
}
</style>
